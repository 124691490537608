.header {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  z-index: 4;
  transition: 1s linear;
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}
/* @media (min-width: 769px) { */
.header.header-scroll {
  background-color: var(--dark-color);
  box-shadow: 0px 2px 2px var(--text-color);
  height: 5rem;
}

.header .lamar-container.lamar-container-scroll {
  display: grid;
  height: 5rem;
  grid-template-columns: 25% 55% 20%;
  padding: 0;
}

@media (max-width: 768px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 47% 35% 20%;
  }
}
@media (max-width: 324px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 51% 32% 20%;
  }
}
.header .lamar-container .image.image-scroll {
  grid-area: 1 / 1;
  height: 5rem;
  width: 115px;
  margin: auto;
  display: flex;
}
.header .lamar-container .image.image-scroll a {
  display: flex;
}
.header .lamar-container .image.image-scroll a div {
  width: 5rem;
  align-items: center;
  position: relative;
  top: 20px;
}
@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div {
    width: 5rem;
    position: relative;
    top: 3px;
  }
}
.header .lamar-container .image.image-scroll a div h4 {
  margin: auto;
  color: var(--text-color);
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div h4 {
    font-size: 18px;
  }
}
.header .lamar-container .image.image-scroll a div h4:nth-child(2) {
  letter-spacing: 3px;
}
.header .lamar-container .main-nav.main-nav-scroll {
  height: 5rem;
}

.header .lamar-container .rightContainer.rightContainer-scroll {
  height: 5rem;
}

.header .lamar-container .main-nav.main-nav-scroll > li > a > a {
  height: 5rem;
}
.header .lamar-container .main-nav.main-nav-scroll > li > a > a:hover {
  background-color: var(--text-color);
  color: var(--dark-color);
  font-weight: 600;
}
/* } */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .main-nav.main-nav-scroll > li > a > a {
    height: 5rem;
    padding-right: 5px;
  }
}
@media (min-width: 531px) and (max-width: 768px) {
  .header.header-scroll {
    background-color: var(--dark-color);
    box-shadow: 0px 2px 2px var(--text-color);
    top: 0;
    height: 5rem;
  }
}

/* very small */
@media (max-width: 399px) {
  .header {
    top: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .header {
    height: 5rem;
  }

  .header.header-scroll {
    background-color: var(--dark-color);
    box-shadow: 0px 2px 2px var(--text-color);
    top: 0;
    height: 5rem;
  }
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}

.header .vertical-nav-container {
  position: absolute;
  background-color: #002c38e6;
  height: 100vh;
  width: 50%;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: width 1s linear;
  padding-top: 75px;
}
@media (min-width: 769px) {
  .header .vertical-nav-container {
    display: none;
  }
}
@keyframes width {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 50%;
  }
}
.header .vertical-nav-container span {
  font-size: 40px;
}
.header .vertical-nav-container .main-nav-phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone > li {
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .fa-angle-down {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone .fa-angle-up {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  transform: rotate(360deg);
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone > li > a {
  padding: 10px;
  width: 100%;
  height: 30px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone > li > a > a {
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone {
  display: none;
  transition: 1s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone.drop-ul-phone-scroll {
  display: block;
  transition: 0.4s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li {
  width: 100%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li::before {
  content: '';
  position: absolute;
  background-color: black;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover::before {
  width: 18%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li a {
  color: var(--text-color);
  font-size: 14px;
  transition: 0.3s;
  font-weight: 500;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover a {
  font-weight: bold;
}
.header .lamar-container {
  display: grid;
  height: 5rem;
  grid-template-columns: 40% 20% 40%;

  position: relative;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container {
    width: 95%;
    display: grid;
    /* height: 5rem; */
    grid-template-columns: 20% 67% 10%;
    gap: 5px;
    height: 5rem;
    padding: 0;
    margin: 0 auto;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 33% 35% 30%;
    gap: 0;
    position: relative;
  }
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 32% 30% 38%;
    gap: 5px;
    position: relative;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////// */
.header .lamar-container .three-dashs {
  display: none;
  width: 30px;
  position: relative;
  cursor: pointer;
}

.header .lamar-container .three-dashs span {
  position: absolute;
  height: 4px;
  width: 25px;
  background-color: var(--text-color);
  border-radius: 10px;
  transition: 0.5s;
}
.header .lamar-container .three-dashs span:nth-child(1) {
  transform: translateY(-8px);
  width: 20px;
  left: 3px;
}
.header .lamar-container .three-dashs span:nth-child(2) {
  transform: translateY(8px);
  width: 15px;
  left: 3px;
}
.header .lamar-container .three-dashs.active {
  position: relative;
  z-index: 5;
  cursor: pointer;
}
.header .lamar-container .three-dashs.active span:nth-child(3) {
  transform: translateX(60px);
}

.header .lamar-container .three-dashs.active span:nth-child(1) {
  transform: translateY(0px) rotate(45deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.125s;
}
.header .lamar-container .three-dashs.active span:nth-child(2) {
  transform: translateY(0px) rotate(315deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.25s;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    height: 30px;
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    height: 30px;
    box-shadow: 0 10px 20px rgb(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}
/* Small */
@media (min-width: 768px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

/*center side*/
.header .lamar-container .image {
  width: 160px;
  position: relative;
  transition: 1s linear;
  height: 140px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .image {
    width: 5rem;
    height: 60px;
  }
}

/* phones */
@media (min-width: 653px) and (max-width: 768px) {
  .header .lamar-container .image {
    width: 110px;
    height: 90px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .image {
    width: 130px;
    height: 110px;
  }
}

.header .lamar-container .image .logo {
  width: 100px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: -55px;
  transform: translateX(-50%);
  cursor: pointer;
  transition: 1s linear;
}
.header .lamar-container .image .small-logo {
  width: 100px;
  height: 100px;
  transition: 1s linear;
  border-radius: 50%;
}

@media (max-width: 450px) {
  .header .lamar-container .image .small-logo {
    width: 45px;
    height: 45px;
  }
}

/* left side*/
.header .lamar-container .main-nav {
  display: flex;
  transition: 1s linear;
  position: relative;
}

.header .lamar-container .main-nav li {
  position: relative;
  height: 5rem;
  margin: auto 0;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  cursor: pointer;
}

.header .lamar-container .main-nav li:hover ul {
  display: block;
}
.header .lamar-container .main-nav > li > a {
  width: 100%;
}
.header .lamar-container .main-nav > li > a > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: var(--text-color);
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  transition: var(--main-transition);
  cursor: pointer;
  text-transform: uppercase;
}
.header .lamar-container .main-nav > li > a > a:hover {
  padding-top: 5px;
  color: var(--text-color);
}

.header .lamar-container .main-nav > li > a > a > i {
  font-size: 15px;
  margin-left: 15px;
}
/* for drop down  */
.header .lamar-container .main-nav .drop-ul-home {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: #ececec;
  height: auto;
  width: 100%;
}

.header .lamar-container .main-nav .drop-ul-home li {
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home a {
  display: flex;
  align-items: center;
  color: black;
  font-size: 15px;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
/* end drop down  */
/* start  drop down on scroll */
.header .lamar-container .main-nav .drop-ul-home-scroll {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: var(--text-color);
  height: auto;
  width: 100%;
}
.header .lamar-container .main-nav .drop-ul-home-scroll li {
  width: 100%;
  height: 30px;
  background-color: var(--text-color);
  box-shadow: 0 2px 5px var(--dark-color);
  color: var(--dark-color);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home-scroll a {
  display: flex;
  align-items: center;
  color: var(--dark-color);
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.header .lamar-container .main-nav li > ul > li:hover {
  padding-left: 10px;
  background-color: var(--dark-color);
}
.header .lamar-container .main-nav li > ul > li:hover a {
  color: var(--text-color);

}
/* end drop down  */

/* phones */
@media (max-width: 768px) {
  .header .lamar-container .main-nav {
    display: none;
  }
}

/* right container >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
.header .lamar-container .rightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer {
    height: 5rem;
  }
}
.header .lamar-container .rightContainer .right-nav {
  display: flex;
  gap: 8px;
}
@media (min-width: 350px) {
  .header .lamar-container .rightContainer .right-nav {
    gap: 12px;
  }
}
.header .lamar-container .rightContainer .right-nav > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: black;
  padding: 0 5px;
  cursor: pointer;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}

/* search box styling >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.header .lamar-container .rightContainer .searchContainer {
  background: transparent;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
}

.header .lamar-container .rightContainer .searchContainer .input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 0px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.8s;
  width: 180px;
  border-bottom: 1px solid;
  cursor: auto;
}
.header .lamar-container .rightContainer .searchContainer .input::placeholder {
  transition: 0.3s;
}

.header .lamar-container .rightContainer .searchContainer .input:focus::placeholder {
  opacity: 0;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100px;
  }
}

.header .lamar-container .rightContainer .searchContainer .btn .fas {
  color: black;
}

.header .lamar-container .rightContainer .right-nav .header-icons {
  font-size: 35px;
  padding: 0 6px;
}
.header .lamar-container .rightContainer .right-nav li {
  position: relative;
}

.header .lamar-container .rightContainer .right-nav a .number {
  color: var(--main-transition);
  position: absolute;
  right: 0px;
  top: 26px;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color-alt);
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.582);
}

.header .lamar-container .rightContainer .right-nav .cart {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  transition: 0.3s;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .cart:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

.header .lamar-container .rightContainer .right-nav li {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile:hover {
  color: whitesmoke;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
  background-color: black;
  border-radius: 25%;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .fav {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .fav:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

.right-nav .fab {
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
}
@media (max-width: 324px) {
  .right-nav .fab {
    font-size: 20px;
  }
}
/* language slect styles */
.header .selectpicker {
  width: 45px;
  color: var(--dark-color);
}
@media (max-width: 324px) {
  .header .selectpicker {
    width: 41px;
    font-size: 14px;
  }
}

.header .close-vertical {
  position: absolute;
  height: 100vh;
  width: 100%;
  opacity: 0;
  background-color: rgba(238, 10, 10, 0.86);
  left: 0;
  top: 0;
}
@media (min-width: 769px) {
  .header .close-vertical {
    display: none;
  }
}