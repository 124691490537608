.students-achievements {
  position: relative;
  height: 100vh;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.students-achievements .lamar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  max-width: 100%;
}
@media (max-width: 490px) {
  .students-achievements .lamar-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .students-achievements .lamar-container {
    width: 100%;
  }
}

.students-achievements .lamar-container .mainTitle {
  color: var(--dark-color);
  margin-bottom: 40px;
  margin-top: 50px;
  position: relative;
}
.students-achievements .lamar-container .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}

@media (max-width: 500px) {
  .students-achievements .lamar-container .mainTitle::before {
    max-width: 105%
  }
}


.students-achievements .lamar-container .carousel-box {
  min-height: 73vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* perspective: 100rem; */
  perspective: 37rem;
  padding-top: 7vh;
  padding-bottom: 2vh;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--dark-color);
  box-shadow: 0 2px 12px gray;
}

@media (max-width: 1000px) {
  .students-achievements .lamar-container .carousel-box {
    min-height: 50vh;
  }
}

.students-achievements .lamar-container .carousel__cards {
  position: relative;
  width: 22rem;
  aspect-ratio: 1;
  margin-bottom: 40px;
  transform-style: preserve-3d;
  transform: translateZ(-25rem);
  transition: transform 0.3s ease-in;
}

@media (max-width: 400px) {
  .students-achievements .lamar-container .carousel__cards {
    max-width: 300px;
  }
}
@media (max-width: 340px) {
  .students-achievements .lamar-container .carousel__cards {
    max-width: 250px;
  }
}

.students-achievements .lamar-container .carousel__img {
  display: inline-block;
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 1;
}
.students-achievements .lamar-container .carousel__card {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid white;
  border-radius: 0.2rem;
  font-size: 3em;
  font-weight: 700;
  transition: all 0.3s ease-in;
}
.students-achievements .lamar-container .carousel__card img {
  border-radius: 4px;
}
.students-achievements .lamar-container .carousel__card img:hover {
  transform: scale(1.08);
  transition: all .3s ease-in;
  cursor: pointer;
  bottom: 20px;
  -webkit-box-reflect: below 25px linear-gradient(0deg, white 0%, transparent 20%);
}
.students-achievements .lamar-container .carousel__card:nth-child(1) {
  transform: rotateY(0deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(2) {
  transform: rotateY(45deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(3) {
  transform: rotateY(90deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(4) {
  transform: rotateY(135deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(5) {
  transform: rotateY(180deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(6) {
  transform: rotateY(225deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(7) {
  transform: rotateY(270deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(8) {
  transform: rotateY(315deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__control {
  z-index: 2;
}

.students-achievements .lamar-container .carousel__btn {
  outline: none;
  border: none;
  border-radius: 0.2rem;
  background: transparent;
  padding: 0.5em 1em;
  font-size: 1.2em;
  font-weight: 500;
  color: var(--text-color);
  cursor: pointer;
  margin: 0 2rem;
  z-index: 10;
}
.students-achievements .lamar-container .carousel__btn svg {
  width: 50px;
  fill: var(--text-color);
  opacity: 0.5;
}
.students-achievements .lamar-container .carousel__btn svg:hover {
  opacity: 1;
}