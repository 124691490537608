.view-image-component {
    position: fixed;
    z-index: 4;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000b0;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-image-component img {
    max-height: 90%;
    min-height: 500px;
    min-width: 320px;
    border-radius: 4px;
    cursor: auto;
}
