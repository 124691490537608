.contact-us {
  padding: 23vh 0 100px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--text-color);
  color: var(--main-color-alt3);
}
@media (max-width: 530px) {
  .contact-us {
    padding: 125px 0;
  }
}
@media (min-width: 530px) and (max-width: 767px) {
  .contact-us {
    padding: 145px 0;
  }
}
.contact-us .nav-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
}
.contact-us .nav-info i {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-right: 10px;
}

.contact-us .left-nav .i-home {
  font-size: 18px;
  /* color: black; */
  font-weight: 600;
}
.contact-us .nav-info span {
  /* color: black; */
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.contact-us .lamar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-us .lamar-container h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  /* color: black; */
  position: relative;
  text-align: center;
  margin-bottom: 35px;
}
.contact-us .lamar-container h2::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 50%;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--main-color-alt3);
  border-radius: 20px;
}
@media (max-width: 767px) {
  .contact-us .lamar-container h2 {
    font-size: 20px;
  }
}

.contact-us .lamar-container form {
  width: 600px;
  max-width: 90%;
}

.contact-us .lamar-container .form-group.flex {
  display: flex;
  gap: 15px;
}
.contact-us .lamar-container .form-group {
  padding: 10px;
}
.contact-us .lamar-container .form-group button{
  width: 100%;
  background-color: var(--dark-color);
  color: var(--main-color-alt3);
  border: none;
  font-weight: 600;
}
.contact-us .lamar-container .form-group button:hover {
  background-color: var(--main-color-alt3);
  color: var(--dark-color);
}

.contact-us .lamar-container .email {
  display: flex;
  margin: 5px;
  align-items: center;
}

.contact-us .lamar-container .email h5 {
  margin: 0;
  margin-right: 10px;
  color: gray;
  font-size: 16px;
}

.contact-us .lamar-container .email a {
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color);
}

/* switch button */
.contact-us .lamar-container .switch-button {
  background: var(--dark-color);
  border-radius: 10px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color:var(--text-color);
  position: relative;
  padding-right: 120px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
}
.contact-us .lamar-container .switch-button:before {
  content: "Whatsapp";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.contact-us .lamar-container .switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.contact-us .lamar-container .switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: var(--main-color-alt3);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  transform: translateX(0);
  transition: transform 300ms;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
/* toggle switch ends */

.contact-us .lamar-container .whatsapp_contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
}
.contact-us .lamar-container .whatsapp_contact a{
  color: var(--main-color-alt3);
}
.contact-us .lamar-container .whatsapp_contact .fa-whatsapp.large_icon {
  font-size: 50px;
}

/* override bootstrap modal style*/

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}