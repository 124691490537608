.hero {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.hero img {
  height: 100%;
}

@media (max-width: 1100px) {
  .hero img {
    width: 100%;
  }
}

