@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
.header {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  z-index: 4;
  transition: 1s linear;
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}
/* @media (min-width: 769px) { */
.header.header-scroll {
  background-color: var(--dark-color);
  box-shadow: 0px 2px 2px var(--text-color);
  height: 5rem;
}

.header .lamar-container.lamar-container-scroll {
  display: grid;
  height: 5rem;
  grid-template-columns: 25% 55% 20%;
  padding: 0;
}

@media (max-width: 768px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 47% 35% 20%;
  }
}
@media (max-width: 324px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 51% 32% 20%;
  }
}
.header .lamar-container .image.image-scroll {
  grid-area: 1 / 1;
  height: 5rem;
  width: 115px;
  margin: auto;
  display: flex;
}
.header .lamar-container .image.image-scroll a {
  display: flex;
}
.header .lamar-container .image.image-scroll a div {
  width: 5rem;
  align-items: center;
  position: relative;
  top: 20px;
}
@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div {
    width: 5rem;
    position: relative;
    top: 3px;
  }
}
.header .lamar-container .image.image-scroll a div h4 {
  margin: auto;
  color: var(--text-color);
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div h4 {
    font-size: 18px;
  }
}
.header .lamar-container .image.image-scroll a div h4:nth-child(2) {
  letter-spacing: 3px;
}
.header .lamar-container .main-nav.main-nav-scroll {
  height: 5rem;
}

.header .lamar-container .rightContainer.rightContainer-scroll {
  height: 5rem;
}

.header .lamar-container .main-nav.main-nav-scroll > li > a > a {
  height: 5rem;
}
.header .lamar-container .main-nav.main-nav-scroll > li > a > a:hover {
  background-color: var(--text-color);
  color: var(--dark-color);
  font-weight: 600;
}
/* } */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .main-nav.main-nav-scroll > li > a > a {
    height: 5rem;
    padding-right: 5px;
  }
}
@media (min-width: 531px) and (max-width: 768px) {
  .header.header-scroll {
    background-color: var(--dark-color);
    box-shadow: 0px 2px 2px var(--text-color);
    top: 0;
    height: 5rem;
  }
}

/* very small */
@media (max-width: 399px) {
  .header {
    top: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .header {
    height: 5rem;
  }

  .header.header-scroll {
    background-color: var(--dark-color);
    box-shadow: 0px 2px 2px var(--text-color);
    top: 0;
    height: 5rem;
  }
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}

.header .vertical-nav-container {
  position: absolute;
  background-color: #002c38e6;
  height: 100vh;
  width: 50%;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: width 1s linear;
          animation: width 1s linear;
  padding-top: 75px;
}
@media (min-width: 769px) {
  .header .vertical-nav-container {
    display: none;
  }
}
@-webkit-keyframes width {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 50%;
  }
}
@keyframes width {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 50%;
  }
}
.header .vertical-nav-container span {
  font-size: 40px;
}
.header .vertical-nav-container .main-nav-phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone > li {
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .fa-angle-down {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone .fa-angle-up {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone > li > a {
  padding: 10px;
  width: 100%;
  height: 30px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone > li > a > a {
  color: var(--text-color);
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone {
  display: none;
  transition: 1s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone.drop-ul-phone-scroll {
  display: block;
  transition: 0.4s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li {
  width: 100%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li::before {
  content: '';
  position: absolute;
  background-color: black;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover::before {
  width: 18%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li a {
  color: var(--text-color);
  font-size: 14px;
  transition: 0.3s;
  font-weight: 500;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover a {
  font-weight: bold;
}
.header .lamar-container {
  display: grid;
  height: 5rem;
  grid-template-columns: 40% 20% 40%;

  position: relative;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container {
    width: 95%;
    display: grid;
    /* height: 5rem; */
    grid-template-columns: 20% 67% 10%;
    grid-gap: 5px;
    gap: 5px;
    height: 5rem;
    padding: 0;
    margin: 0 auto;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 33% 35% 30%;
    grid-gap: 0;
    gap: 0;
    position: relative;
  }
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 32% 30% 38%;
    grid-gap: 5px;
    gap: 5px;
    position: relative;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////// */
.header .lamar-container .three-dashs {
  display: none;
  width: 30px;
  position: relative;
  cursor: pointer;
}

.header .lamar-container .three-dashs span {
  position: absolute;
  height: 4px;
  width: 25px;
  background-color: var(--text-color);
  border-radius: 10px;
  transition: 0.5s;
}
.header .lamar-container .three-dashs span:nth-child(1) {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  width: 20px;
  left: 3px;
}
.header .lamar-container .three-dashs span:nth-child(2) {
  -webkit-transform: translateY(8px);
          transform: translateY(8px);
  width: 15px;
  left: 3px;
}
.header .lamar-container .three-dashs.active {
  position: relative;
  z-index: 5;
  cursor: pointer;
}
.header .lamar-container .three-dashs.active span:nth-child(3) {
  -webkit-transform: translateX(60px);
          transform: translateX(60px);
}

.header .lamar-container .three-dashs.active span:nth-child(1) {
  -webkit-transform: translateY(0px) rotate(45deg);
          transform: translateY(0px) rotate(45deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.125s;
}
.header .lamar-container .three-dashs.active span:nth-child(2) {
  -webkit-transform: translateY(0px) rotate(315deg);
          transform: translateY(0px) rotate(315deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.25s;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    height: 30px;
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    height: 30px;
    box-shadow: 0 10px 20px rgb(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}
/* Small */
@media (min-width: 768px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

/*center side*/
.header .lamar-container .image {
  width: 160px;
  position: relative;
  transition: 1s linear;
  height: 140px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .image {
    width: 5rem;
    height: 60px;
  }
}

/* phones */
@media (min-width: 653px) and (max-width: 768px) {
  .header .lamar-container .image {
    width: 110px;
    height: 90px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .image {
    width: 130px;
    height: 110px;
  }
}

.header .lamar-container .image .logo {
  width: 100px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: -55px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  transition: 1s linear;
}
.header .lamar-container .image .small-logo {
  width: 100px;
  height: 100px;
  transition: 1s linear;
  border-radius: 50%;
}

@media (max-width: 450px) {
  .header .lamar-container .image .small-logo {
    width: 45px;
    height: 45px;
  }
}

/* left side*/
.header .lamar-container .main-nav {
  display: flex;
  transition: 1s linear;
  position: relative;
}

.header .lamar-container .main-nav li {
  position: relative;
  height: 5rem;
  margin: auto 0;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  cursor: pointer;
}

.header .lamar-container .main-nav li:hover ul {
  display: block;
}
.header .lamar-container .main-nav > li > a {
  width: 100%;
}
.header .lamar-container .main-nav > li > a > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: var(--text-color);
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  transition: var(--main-transition);
  cursor: pointer;
  text-transform: uppercase;
}
.header .lamar-container .main-nav > li > a > a:hover {
  padding-top: 5px;
  color: var(--text-color);
}

.header .lamar-container .main-nav > li > a > a > i {
  font-size: 15px;
  margin-left: 15px;
}
/* for drop down  */
.header .lamar-container .main-nav .drop-ul-home {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: #ececec;
  height: auto;
  width: 100%;
}

.header .lamar-container .main-nav .drop-ul-home li {
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home a {
  display: flex;
  align-items: center;
  color: black;
  font-size: 15px;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
/* end drop down  */
/* start  drop down on scroll */
.header .lamar-container .main-nav .drop-ul-home-scroll {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: var(--text-color);
  height: auto;
  width: 100%;
}
.header .lamar-container .main-nav .drop-ul-home-scroll li {
  width: 100%;
  height: 30px;
  background-color: var(--text-color);
  box-shadow: 0 2px 5px var(--dark-color);
  color: var(--dark-color);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home-scroll a {
  display: flex;
  align-items: center;
  color: var(--dark-color);
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.header .lamar-container .main-nav li > ul > li:hover {
  padding-left: 10px;
  background-color: var(--dark-color);
}
.header .lamar-container .main-nav li > ul > li:hover a {
  color: var(--text-color);

}
/* end drop down  */

/* phones */
@media (max-width: 768px) {
  .header .lamar-container .main-nav {
    display: none;
  }
}

/* right container >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
.header .lamar-container .rightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer {
    height: 5rem;
  }
}
.header .lamar-container .rightContainer .right-nav {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
@media (min-width: 350px) {
  .header .lamar-container .rightContainer .right-nav {
    grid-gap: 12px;
    gap: 12px;
  }
}
.header .lamar-container .rightContainer .right-nav > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: black;
  padding: 0 5px;
  cursor: pointer;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}

/* search box styling >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.header .lamar-container .rightContainer .searchContainer {
  background: transparent;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
}

.header .lamar-container .rightContainer .searchContainer .input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 0px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.8s;
  width: 180px;
  border-bottom: 1px solid;
  cursor: auto;
}
.header .lamar-container .rightContainer .searchContainer .input::-webkit-input-placeholder {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .searchContainer .input::placeholder {
  transition: 0.3s;
}

.header .lamar-container .rightContainer .searchContainer .input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.header .lamar-container .rightContainer .searchContainer .input:focus::placeholder {
  opacity: 0;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100px;
  }
}

.header .lamar-container .rightContainer .searchContainer .btn .fas {
  color: black;
}

.header .lamar-container .rightContainer .right-nav .header-icons {
  font-size: 35px;
  padding: 0 6px;
}
.header .lamar-container .rightContainer .right-nav li {
  position: relative;
}

.header .lamar-container .rightContainer .right-nav a .number {
  color: var(--main-transition);
  position: absolute;
  right: 0px;
  top: 26px;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color-alt);
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.582);
}

.header .lamar-container .rightContainer .right-nav .cart {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  transition: 0.3s;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .cart:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

.header .lamar-container .rightContainer .right-nav li {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile:hover {
  color: whitesmoke;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
  background-color: black;
  border-radius: 25%;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .fav {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .fav:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

.right-nav .fab {
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
}
@media (max-width: 324px) {
  .right-nav .fab {
    font-size: 20px;
  }
}
/* language slect styles */
.header .selectpicker {
  width: 45px;
  color: var(--dark-color);
}
@media (max-width: 324px) {
  .header .selectpicker {
    width: 41px;
    font-size: 14px;
  }
}

.header .close-vertical {
  position: absolute;
  height: 100vh;
  width: 100%;
  opacity: 0;
  background-color: rgba(238, 10, 10, 0.86);
  left: 0;
  top: 0;
}
@media (min-width: 769px) {
  .header .close-vertical {
    display: none;
  }
}
.brand {
  padding-top: var(--main-padding-top);
  position: relative;
}
@media (max-width: 1094px) {
  .brand {
    margin-bottom: 50px;
  }
}
@media (max-width: 530px) {
  .brand {
    padding-top: 40px;
  }
}
.brand .title-section {
  margin: 0 auto 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.brand .mainTitle {
  color: var(--dark-color);
  margin-bottom: 30px;
  position: relative;
}
.brand .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}

@media (max-width: 500px) {
  .brand .mainTitle::before {
    width: 103%;
  }
}

.clinic {
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 20px;
}

.clinic .part {
  margin: 10px;
  width: 25%;
}
.clinic .part h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--main-color-alt3);
}
.clinic .part p {
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: var(--main-color-alt3);
  line-height: 1.7;
  font-size: 16px;
}
.clinic i {
  font-size: 50px;
  color: var(--main-color-alt3);
  margin-bottom: 20px;
}

.brand .custom-shape-divider-top-1638712956 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.brand .custom-shape-divider-top-1638712956 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 90px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.brand .custom-shape-divider-top-1638712956 .shape-fill {
  fill: rgba(0, 0, 0, 0.89);
}

.brand .lamar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 0;
  width: 93%;
  position: relative;
  left: 3.5%;
  border-radius: 10px;
}

@media (max-width: 1094px) {
  .brand .lamar-container {
    grid-gap: 35px;
    gap: 35px;
  }
}

.brand .lamar-container > div {
  position: relative;
  color: var(--main-color-alt3);
  height: 100%;
  transition: box-shadow 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, box-shadow 0.5s;
  transition: transform 0.5s, box-shadow 0.5s, -webkit-transform 0.5s;
  cursor: pointer;
}


.brand .lamar-container .card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 550px !important;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  text-align: center;
  justify-content: space-between;
}
@media (max-width: 1212px) {
  .brand .lamar-container .card {
    width: 400px;
    height: 500px !important;
  }
}
@media (max-width: 1094px) {
  .brand .lamar-container .card {
    width: 275px;
    height: 275px !important;
  }
}

.brand .lamar-container .course-card {
  border: none;
  border-radius: 5px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.brand .lamar-container .course-card:hover {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.brand .lamar-container .course-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--main-color-alt3);
}

.brand .lamar-container ul {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
  text-align: left;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 !important;
}
.brand .lamar-container ul.right {
  text-align: right;
}

.brand .lamar-container li {
  margin-bottom: 0.5rem;
  /* color: grey; */
  color: var(--text-color);
}

.brand .lamar-container .contact-btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--text-color);
  color: var(--main-color-alt3);
  border-color: var(--text-color);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  width: 200px;
}

.brand .lamar-container .contact-btn:hover {
  background-color: var(--main-color-alt3);
  color: var(--text-color);
}
.brand .lamar-container .hr {
  width: 50%;
  position: relative;
  height: 3px;
  margin: 0;
  margin-bottom: 5px;
  left: 25%;
  opacity: 1;
  color: var(--main-color-alt3);
  transition: all 0.3s ease;
}

.brand .lamar-container .course-card:hover > .hr {
  width: 85%;
  left: 7.5%;
} 

.brand .lamar-container .button-text {
  font-weight: 600;
} 
.brand .lamar-container .button-text.arabic {
  font-size: 18px;
} 
.button-component {
    position: relative;
    right: 0%;
    bottom: 127px;
}
@media (max-width: 1212px) {
    .button-component {
        bottom: 90px;
    }
}
@media (max-width: 1094px) {
    .button-component {
        bottom: 61px;
    }
}
@media (max-width: 350px) {
    .button-component {
        bottom: 58px;
    }
}
.button-component  button {
    position: relative;
    background: var(--text-color);
    color: var(--text-color);
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
    min-width: 215px;
}

.button-component  button:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background: var(--text-color);
    color: var(--text-color);
    -webkit-animation: box 3s infinite;
            animation: box 3s infinite;
}
@media (max-width: 1094px) {
    .button-component  button {
        letter-spacing: 1px;
        font-size: 12px;
        padding: 2px;
        min-width: unset;
        width: 150px;
        height: 38px;
    }
    
    .button-component  button:hover {
        letter-spacing: 4px;
        padding: 5px;
    }
} 
.button-component  button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: var(--dark-color);
}
  
.button-component  button span {
    position: relative;
    z-index: 1;
}
.button-component button i {
    position: absolute;
    inset: 0;
    display: block;
}
  
.button-component button i::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 7px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--text-color);
    background: var(--dark-color);
    transition: 0.2s;
}
  
.button-component  button:hover i::before {
    width: 25px;
    left: 20%;
    -webkit-animation: move 3s infinite;
            animation: move 3s infinite;
}
  
.button-component  button i::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 7px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--text-color);
    background: var(--dark-color);
    transition: 0.2s;
}
  
.button-component  button:hover i::after {
    width: 25px;
    left: 80%;
    -webkit-animation: move 3s infinite;
            animation: move 3s infinite;
}

@media (max-width: 1212px) {

}

@media (max-width: 1094px) {
    .button-component button i::before {
        width: 15px;
        height: 5px;
    }
      
    .button-component  button:hover i::before {
        width: 15px;
    }
      
    .button-component  button i::after {
        width: 15px;
        height: 5px;
    }
      
    .button-component  button:hover i::after {
        width: 15px;
    }
}
@-webkit-keyframes move {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes move {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes box {
    0% {
        box-shadow: #27272c;
    }
    50% {
        box-shadow: 0 0 25px var(--text-color);
    }
    100% {
        box-shadow: #27272c;
    }
}

@keyframes box {
    0% {
        box-shadow: #27272c;
    }
    50% {
        box-shadow: 0 0 25px var(--text-color);
    }
    100% {
        box-shadow: #27272c;
    }
}

.hero {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

.hero img {
  height: 100%;
}

@media (max-width: 1100px) {
  .hero img {
    width: 100%;
  }
}


.students-achievements {
  position: relative;
  height: 100vh;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.students-achievements .lamar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  max-width: 100%;
}
@media (max-width: 490px) {
  .students-achievements .lamar-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .students-achievements .lamar-container {
    width: 100%;
  }
}

.students-achievements .lamar-container .mainTitle {
  color: var(--dark-color);
  margin-bottom: 40px;
  margin-top: 50px;
  position: relative;
}
.students-achievements .lamar-container .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}

@media (max-width: 500px) {
  .students-achievements .lamar-container .mainTitle::before {
    max-width: 105%
  }
}


.students-achievements .lamar-container .carousel-box {
  min-height: 73vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* perspective: 100rem; */
  -webkit-perspective: 37rem;
          perspective: 37rem;
  padding-top: 7vh;
  padding-bottom: 2vh;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--dark-color);
  box-shadow: 0 2px 12px gray;
}

@media (max-width: 1000px) {
  .students-achievements .lamar-container .carousel-box {
    min-height: 50vh;
  }
}

.students-achievements .lamar-container .carousel__cards {
  position: relative;
  width: 22rem;
  aspect-ratio: 1;
  margin-bottom: 40px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(-25rem);
          transform: translateZ(-25rem);
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}

@media (max-width: 400px) {
  .students-achievements .lamar-container .carousel__cards {
    max-width: 300px;
  }
}
@media (max-width: 340px) {
  .students-achievements .lamar-container .carousel__cards {
    max-width: 250px;
  }
}

.students-achievements .lamar-container .carousel__img {
  display: inline-block;
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 1;
}
.students-achievements .lamar-container .carousel__card {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid white;
  border-radius: 0.2rem;
  font-size: 3em;
  font-weight: 700;
  transition: all 0.3s ease-in;
}
.students-achievements .lamar-container .carousel__card img {
  border-radius: 4px;
}
.students-achievements .lamar-container .carousel__card img:hover {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  transition: all .3s ease-in;
  cursor: pointer;
  bottom: 20px;
  -webkit-box-reflect: below 25px linear-gradient(0deg, white 0%, transparent 20%);
}
.students-achievements .lamar-container .carousel__card:nth-child(1) {
  -webkit-transform: rotateY(0deg) translateZ(28rem);
          transform: rotateY(0deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(2) {
  -webkit-transform: rotateY(45deg) translateZ(28rem);
          transform: rotateY(45deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(3) {
  -webkit-transform: rotateY(90deg) translateZ(28rem);
          transform: rotateY(90deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(4) {
  -webkit-transform: rotateY(135deg) translateZ(28rem);
          transform: rotateY(135deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(5) {
  -webkit-transform: rotateY(180deg) translateZ(28rem);
          transform: rotateY(180deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(6) {
  -webkit-transform: rotateY(225deg) translateZ(28rem);
          transform: rotateY(225deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(7) {
  -webkit-transform: rotateY(270deg) translateZ(28rem);
          transform: rotateY(270deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__card:nth-child(8) {
  -webkit-transform: rotateY(315deg) translateZ(28rem);
          transform: rotateY(315deg) translateZ(28rem);
}
.students-achievements .lamar-container .carousel__control {
  z-index: 2;
}

.students-achievements .lamar-container .carousel__btn {
  outline: none;
  border: none;
  border-radius: 0.2rem;
  background: transparent;
  padding: 0.5em 1em;
  font-size: 1.2em;
  font-weight: 500;
  color: var(--text-color);
  cursor: pointer;
  margin: 0 2rem;
  z-index: 10;
}
.students-achievements .lamar-container .carousel__btn svg {
  width: 50px;
  fill: var(--text-color);
  opacity: 0.5;
}
.students-achievements .lamar-container .carousel__btn svg:hover {
  opacity: 1;
}
.view-image-component {
    position: fixed;
    z-index: 4;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000b0;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-image-component img {
    max-height: 90%;
    min-height: 500px;
    min-width: 320px;
    border-radius: 4px;
    cursor: auto;
}

.feedback {
  position: relative;
  height: 540px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.feedback .lamar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

@media (max-width: 490px) {
  .feedback {
    height: 430px;
  }
}

@media (max-width: 490px) {
  .feedback .lamar-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .feedback .lamar-container {
    width: 100%;
  }
}
.feedback div {
  height: 100% !important;
}

.feedback .slide-container {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100%;
}
.feedback .slide-container img {
  border-radius: 4px;
  box-shadow: 0 2px 12px gray;
  transition: 0.6s ease-in-out;
}
@media (min-width: 700px){
  .feedback .slide-container img:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.feedback .mainTitle {
  color: var(--dark-color);
  margin-bottom: 30px;
  position: relative;
}
.feedback .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}
.feedback .carousel {
  width: 100%;
}
@media (max-width: 350px) {
  .feedback .carousel {
    width: 300px;
  }
  .feedback .slide-container img {
    width: 100%;
  }
}
.feedback .carousel svg {
  width: 25px;
  fill: var(--dark-color);
}
.shop {
  /* background-image: url(https://www.almotahajiba.com/pub/media/wysiwyg/smartwave/porto/homepage/15/slider/parallax_img_new.jpg); */
  /* background-image: url(../../images/shop/shop3.jpg); */
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/lamar-fashion.appspot.com/o/home-shop%2Fshop5.jpeg?alt=media&token=8705dc05-216b-403f-b64b-c80df640cd4e); */
  padding-top: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 50px;
}
.shop .lamar-container {
  /* height: 55vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 20px; */
  padding: 20px;
  background-color: var(--dark-color);
  border-radius: 10px;
  box-shadow: 0 2px 12px gray;
  grid-gap: 20px;
  gap: 20px;
}

.shop .rightSection .image {
  width: 90%;
}

/* @media (max-width: 529px) {
	.shop .lamar-container {
		gap: 10px;
		flex-direction: column;
		width: 90%;
		height: 50vh;
	}
}
@media (min-width: 530px) and (max-width: 768px) {
	.shop .lamar-container {
		gap: 10px;
		flex-direction: column;
		width: 95%;
	}
} */

@media (max-width: 991px) {
  .shop .lamar-container {
    height: 100%;
    display: flex;

    flex-direction: column;
    width: 90%;
  }
  .shop .lamar-container .leftSection {
    width: 100%;
    position: relative;
    padding: 0 30px;
    text-align: center;
    margin: 30px;
  }
  .shop .lamar-container .rightSection {
    position: relative;
    flex: 1 1;
    height: 100%;
    text-align: center;
    width: 70%;
  }
  .shop .lamar-container .leftSection .text {
    text-align: left;
  }
}

/* @media (min-width: 992px) and (max-width: 1199px) {
	.shop .lamar-container {
		gap: 20px;
	}
} */

.shop .leftSection {
  width: 70%;
  position: relative;
  padding-left: 30px;
  text-align: justify;
}
@media (max-width: 529px) {
  .shop {
    margin-top: 0;
  }
  .shop .lamar-container .leftSection {
    width: 100%;
    padding: 0;
  }
  .shop .lamar-container .leftSection .text {
    font-size: 16px;
    text-align: left;
  }
}

.shop .leftSection .shop-title {
  color: var(--main-color-alt3);
}
.shop .leftSection .text {
  font-size: 21px;
  color: var(--text-color);
  font-family: system-ui;
  font-weight: 400;
}

.shop .rightSection {
  position: relative;
  flex: 1 1;
}

.shop .shopImg {
  position: absolute;
  padding: 4px;
  background-color: var(--main-color-alt);
}

.shop .rightSection .image1 {
  height: 220px;
  width: 180px;
  top: 45px;
  left: 15px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.shop .rightSection .image1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image1 {
    height: 150px;
    width: 120px;
    top: 0px;
    left: -150px;
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image1 {
    height: 140px;
    width: 130px;
    top: -45px;
    left: -70px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@media (max-width: 991px) {
  .shop .lamar-container .rightSection {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .shop .rightSection .image {
    width: 100%;
    height: 87%;
  }
}

@media (max-width: 991px) {
  .shop .rightSection .image {
    width: 100%;
    height: 100%;
    max-width: 400px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image1 {
    height: 200px;
    width: 160px;
    top: 35px;
    left: 15px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

.shop .rightSection .image2 {
  height: 230px;
  width: 190px;
  top: 60px;
  left: 160px;
  -webkit-transform: rotate(1.5deg);
          transform: rotate(1.5deg);
}
.shop .rightSection .image2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image2 {
    height: 160px;
    width: 130px;
    top: 15px;
    left: -54px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image2 {
    height: 160px;
    width: 130px;
    top: -55px;
    left: 25px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image2 {
    height: 210px;
    width: 170px;
    top: 45px;
    left: 135px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}

.shop .rightSection .image3 {
  height: 210px;
  width: 160px;
  top: 45px;
  right: 60px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}
.shop .rightSection .image3 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image3 {
    height: 150px;
    width: 120px;
    top: -2px;
    left: 39px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image3 {
    height: 150px;
    width: 120px;
    top: -55px;
    left: 122px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image3 {
    height: 190px;
    width: 150px;
    top: 35px;
    right: 10px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

/* .shop  .rightSection .image4 {
  height: 300px;
  position: absolute;
  bottom: -42px;
  right: 65px;
}
.shop  .rightSection .image4 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.shop .text {
  margin-bottom: 1rem;
  font-weight: lighter;
}

.footer {
  background-color: #fff;
  padding: 70px 0 0;
  border-top: 1px solid var(--dark-color);
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}

.footer .go-up {
}
.footer .lamar-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 40px;
  gap: 40px;
}
@media (min-width: 1200px) {
  .footer .lamar-container {
    width: 1170px !important;
  }
}
@media (max-width: 375px) {
  .footer .lamar-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
}
.footer .box h3 {
  color: var(--dark-color);
  font-size: 30px;
  margin: 0;
}
.footer .box .social {
  display: flex;
}
@media (max-width: 767px) {
  .footer .box .social {
    justify-content: center;
  }
}
.footer .box .social li {
  margin-right: 10px;
  cursor: pointer;
}
.footer .box .social li a {
  background-color: #313131;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  transition: .5s;
  cursor: pointer;
  border-radius: 7px;
}
.footer .box .social li a i {
  cursor: pointer;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
.footer .box .social .facebook {
  background-color: #1877f2;
}
.footer .box .social .facebook:hover {
  box-shadow: 0 5px 8px rgba(26,35,126,.5);
  background: #3949ab;
  background: linear-gradient(90deg,#3949ab 0,#2196f3);
  background-size: 400%;
  -webkit-animation: Gradient 1s ease infinite;
  animation: Gradient 1s ease infinite;
}
.footer .box .social .twitter:hover {
  background-color: #1da1f2;
}
.footer .box .social .instagram {
  background: linear-gradient(15deg, #ffb13d, #dd277b, #4d5ed4);
}
.footer .box .social .instagram:hover {
  box-shadow: 0 5px 8px rgba(63,81,181,.5);
  background: #ffc107;
  background: linear-gradient(90deg,#ffc107 0,#f50057 50%,#3f51b5);
  background-size: 400%;
  -webkit-animation: Gradient 1s ease infinite;
  animation: Gradient 1s ease infinite;
}
.footer .box .text {
  line-height: 2;
  color: var(--dark-color);
}
.footer .box .links li {
  padding: 15px 0;
  transition: var(--main-transition);
  cursor: pointer;
}
.footer .box .links li:not(:last-child) {
}
.footer .box .links li:hover {
  padding-left: 10px;
}
.footer .box .links li:hover a {
  color: var(--dark-color);
}
.footer .box .links li a {
  color: var(--dark-color);
  transition: var(--dark-color);
  cursor: pointer;
}
.footer .box .links li a i {
  font-size: 14px;
  margin-right: 3px;
  color: var(--dark-color);
}
.footer .box .line {
  display: flex;
  align-items: center;
  color: var(--dark-color);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .footer .box .line {
    flex-direction: column;
  }
}
.footer .box .line i {
  font-size: 25px;
  color: var(--main-color-alt3);
  margin-right: 10px;
}
@media (max-width: 767px) {
  .footer .box .line i {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.footer .box .line .info {
  line-height: 1.7;
  flex: 1 1;
  color: var(--dark-color);
}
.footer .box .line .info span {
  display: block;
}
.footer .box .line .info a {
  color: var(--dark-color);
}

.footer .box p {
  color: var(--dark-color);
  margin-bottom: 5px;
}
.footer .footer-gallery img {
  width: 78px;
  border: 3px solid var(--dark-color);
  margin: 2px;
}

.footer ul {
  padding-left: 0;
}
.footer .footer-pay .lamar-container {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 10px;
  gap: 10px;
  border-top: 1px solid var(--dark-color);
  margin-top: 10px;
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container {
    display: grid;
    grid-template-columns: 100%;
  }
}

.footer .footer-pay .lamar-container .copyright {
  padding: 25px 0;
  text-align: left;
  color: var(--dark-color);
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container .copyright {
    padding: 25px 0;
    text-align: center;
    color: var(--dark-color);
  }
}
.footer .footer-pay .lamar-container .info-pay {
  display: flex;
  justify-content: end;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container .info-pay {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    grid-area: 1 / 1;
  }
}
.footer .info-pay .image {
  width: 60px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}
.footer .info-pay .image.pay-pal {
  width: 70px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}
.footer .info-pay img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.fixed-header {
  background-color: black;
  position: fixed;
  height: 2rem;
  width: 100%;
  color: var(--main-color-alt);
  z-index: 4;
}
/* very small */
@media (max-width: 399px) {
  .fixed-header {
    height: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header {
    /* height: 4rem; */
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 652px) {
  .fixed-header {
    height: 3rem;
  }
}

.fixed-header .lamar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

/* very small */
@media (max-width: 399px) {
  .fixed-header .lamar-container {
    height: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container {
    /* height: 4rem; */
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 652px) {
  .fixed-header .lamar-container {
    height: 3rem;
  }
}

.fixed-header .lamar-container .contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container {
    grid-gap: 5px;
    gap: 5px;
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 710px) {
  .fixed-header .lamar-container .contact-container {
    grid-gap: 5px;
    gap: 5px;
  }
}

.fixed-header .lamar-container .contact-container .contact-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container .contact-info.location {
    display: none;
  }
}
/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container .contact-info.email {
    display: none;
  }
}

.fixed-header .lamar-container .contact-container .contact-info .contact-text {
  margin: auto;
  font-size: 14px;
}

.fixed-header .icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.fixed-header .icons-container .icons {
color: var(--main-color-alt);
}
.fixed-header .icons-container .icons i{
  cursor: pointer;
}

.about-us {
	padding: 200px 0 100px;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	background-color: var(--text-color);
	color: var(--main-color-alt3);
}
@media (max-width: 530px) {
	.about-us {
		padding: 150px 0 100px;
	}
}
.about-us .lamar-container {
	padding-bottom: 10px;
}

.about-us .lamar-container .experience div {
	margin-top: 40px;
}

.about-us .lamar-container .eductation {
	margin-top: 40px;
}
@media (max-width: 767px) {
	.about-us .lamar-container {
		width: 100%;
	}
}
.about-us .path {
	text-align: left;
}
.about-us .path i {
	font-size: 22px;
	margin-right: 10px;
	color: var(--main-color-alt3);
}
.about-us .path span {
	font-size: 20px;
	cursor: auto;
	font-weight: 600;
}
.about-us .lamar-container h5 {
	color: var(--dark-color);
}
.about-us .lamar-container > ul {
	list-style: disc;
	padding: 10px 20px !important;
	margin: 10px !important;
}
.about-us .lamar-container > ul > li {
	margin: 10px auto;
}
.about-us .lamar-container > ul > li span {
	font-weight: bold;
}

.delivery-p{
    padding: 200px 0 100px;
    width: 100%;
    background-color: var(--main-color-alt2);
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
@media(max-width:530px){
    .delivery-p{
      padding: 150px 0 100px; 
    }
  }

.delivery-p .lamar-container {
    padding-bottom:10px;
}

@media(max-width:767px){
    .delivery-p .lamar-container {
        width: 100%;
    }
}

.delivery-p  .lamar-container .path{
    text-align: left;
}
.delivery-p .lamar-container .path i {
    font-size: 22px;
    margin-right: 10px;
    color: var(--main-color-alt);
}
.delivery-p  .lamar-container .path span{
    font-size: 20px;
    cursor: auto;
    font-weight: 600;
}
.delivery-p .lamar-container p {
    padding: 0 auto;
}
.delivery-p .lamar-container p span{
    font-weight: bold;
}
.delivery-p .lamar-container ul li {
    font-weight: 700;
    padding: 0 0 0 40px;
    color: var(--text-color);
}
.privacy{
    padding: 200px 0 100px;
    width: 100%;
    background-color: var(--main-color-alt2);
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
@media(max-width:530px){
    .privacy{
      padding: 150px 0 100px; 
    }
  }

.privacy .lamar-container {
    padding-bottom:10px;
}

@media(max-width:767px){
    .privacy .lamar-container {
        width: 100%;
    }
}

.privacy  .lamar-container .path{
    text-align: left;
}
.privacy .lamar-container .path i {
    font-size: 22px;
    margin-right: 10px;
    color: var(--main-color-alt);
}
.privacy  .lamar-container .path span{
    font-size: 20px;
    cursor: auto;
    font-weight: 600;
}
.privacy  .lamar-container ol > li {
    font-weight: 600;
    padding-left:  5px;
    margin-bottom: 10px;
}

.privacy  .lamar-container ol  ul li {
    list-style: disc;

}

.privacy  .lamar-container p span {
    font-weight: bold;
}
.privacy  .lamar-container p {
    word-spacing: 1.8px;
}
.privacy  .lamar-container:nth-child(2) p{
    margin-top: 10px;
}
.contact-us {
  padding: 23vh 0 100px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--text-color);
  color: var(--main-color-alt3);
}
@media (max-width: 530px) {
  .contact-us {
    padding: 125px 0;
  }
}
@media (min-width: 530px) and (max-width: 767px) {
  .contact-us {
    padding: 145px 0;
  }
}
.contact-us .nav-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}
.contact-us .nav-info i {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-right: 10px;
}

.contact-us .left-nav .i-home {
  font-size: 18px;
  /* color: black; */
  font-weight: 600;
}
.contact-us .nav-info span {
  /* color: black; */
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.contact-us .lamar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-us .lamar-container h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  /* color: black; */
  position: relative;
  text-align: center;
  margin-bottom: 35px;
}
.contact-us .lamar-container h2::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 50%;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--main-color-alt3);
  border-radius: 20px;
}
@media (max-width: 767px) {
  .contact-us .lamar-container h2 {
    font-size: 20px;
  }
}

.contact-us .lamar-container form {
  width: 600px;
  max-width: 90%;
}

.contact-us .lamar-container .form-group.flex {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}
.contact-us .lamar-container .form-group {
  padding: 10px;
}
.contact-us .lamar-container .form-group button{
  width: 100%;
  background-color: var(--dark-color);
  color: var(--main-color-alt3);
  border: none;
  font-weight: 600;
}
.contact-us .lamar-container .form-group button:hover {
  background-color: var(--main-color-alt3);
  color: var(--dark-color);
}

.contact-us .lamar-container .email {
  display: flex;
  margin: 5px;
  align-items: center;
}

.contact-us .lamar-container .email h5 {
  margin: 0;
  margin-right: 10px;
  color: gray;
  font-size: 16px;
}

.contact-us .lamar-container .email a {
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color);
}

/* switch button */
.contact-us .lamar-container .switch-button {
  background: var(--dark-color);
  border-radius: 10px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color:var(--text-color);
  position: relative;
  padding-right: 120px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
}
.contact-us .lamar-container .switch-button:before {
  content: "Whatsapp";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.contact-us .lamar-container .switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.contact-us .lamar-container .switch-button-checkbox:checked + .switch-button-label:before {
  -webkit-transform: translateX(120px);
          transform: translateX(120px);
  transition: -webkit-transform 300ms linear;
  transition: transform 300ms linear;
  transition: transform 300ms linear, -webkit-transform 300ms linear;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: var(--main-color-alt3);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.contact-us .lamar-container .switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
/* toggle switch ends */

.contact-us .lamar-container .whatsapp_contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 40px;
}
.contact-us .lamar-container .whatsapp_contact a{
  color: var(--main-color-alt3);
}
.contact-us .lamar-container .whatsapp_contact .fa-whatsapp.large_icon {
  font-size: 50px;
}

/* override bootstrap modal style*/

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
body {
 /* background-color:  var(--background-color); */
}
.loading-circular {
    position: absolute;
    top: 50%;
    margin-top: -2em;
    height: 4em;
    left: 50%;
    margin-left: -6em;
    width: 12em;
  }
  .loading-circular .text-loading {
    color: var(--dark-color);
    /* color: #FFF; */
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
  
  .circular-group {
    position: relative;
    width: 40px;
    height: 40px;
    margin: auto;
  }
  
  [class^=circular_] {
    position: absolute;
    background-color: black;
    /* background-color: rgb(33, 32, 32); */
    width: 9px;
    height: 9px;
    border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    animation-name: bounce_circular;
    -o-animation-name: bounce_circular;
    -ms-animation-name: bounce_circular;
    -webkit-animation-name: bounce_circular;
    -moz-animation-name: bounce_circular;
    animation-duration: 0.578s;
    -o-animation-duration: 0.578s;
    -ms-animation-duration: 0.578s;
    -webkit-animation-duration: 0.578s;
    -moz-animation-duration: 0.578s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
  }
  
  .circular_1 {
    left: 0;
    top: 16px;
    animation-delay: 0.218s;
    -o-animation-delay: 0.218s;
    -ms-animation-delay: 0.218s;
    -webkit-animation-delay: 0.218s;
    -moz-animation-delay: 0.218s;
  }
  
  .circular_2 {
    left: 4px;
    top: 4px;
    animation-delay: 0.284s;
    -o-animation-delay: 0.284s;
    -ms-animation-delay: 0.284s;
    -webkit-animation-delay: 0.284s;
    -moz-animation-delay: 0.284s;
  }
  
  .circular_3 {
    top: 0;
    left: 16px;
    animation-delay: 0.36s;
    -o-animation-delay: 0.36s;
    -ms-animation-delay: 0.36s;
    -webkit-animation-delay: 0.36s;
    -moz-animation-delay: 0.36s;
  }
  
  .circular_4 {
    right: 4px;
    top: 4px;
    animation-delay: 0.436s;
    -o-animation-delay: 0.436s;
    -ms-animation-delay: 0.436s;
    -webkit-animation-delay: 0.436s;
    -moz-animation-delay: 0.436s;
  }
  
  .circular_5 {
    right: 0;
    top: 16px;
    animation-delay: 0.502s;
    -o-animation-delay: 0.502s;
    -ms-animation-delay: 0.502s;
    -webkit-animation-delay: 0.502s;
    -moz-animation-delay: 0.502s;
  }
  
  .circular_6 {
    right: 4px;
    bottom: 4px;
    animation-delay: 0.578s;
    -o-animation-delay: 0.578s;
    -ms-animation-delay: 0.578s;
    -webkit-animation-delay: 0.578s;
    -moz-animation-delay: 0.578s;
  }
  
  .circular_7 {
    left: 16px;
    bottom: 0;
    animation-delay: 0.644s;
    -o-animation-delay: 0.644s;
    -ms-animation-delay: 0.644s;
    -webkit-animation-delay: 0.644s;
    -moz-animation-delay: 0.644s;
  }
  
  .circular_8 {
    left: 4px;
    bottom: 4px;
    animation-delay: 0.72s;
    -o-animation-delay: 0.72s;
    -ms-animation-delay: 0.72s;
    -webkit-animation-delay: 0.72s;
    -moz-animation-delay: 0.72s;
  }
  
  @keyframes bounce_circular {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.3);
              transform: scale(0.3);
    }
  }
  @-webkit-keyframes bounce_circular {
    0% {
      -webkit-transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.3);
    }
  }




  
.pricing-plans {
    padding: 15vh 0 100px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--text-color);
    color: var(--main-color-alt3);
    color: black;
    font-family: "Source Sans Pro", sans-serif;
}

.pricing-plans .lamar-container {
    width: unset;
}

.pricing-plans .path {
	text-align: left;
}
.pricing-plans .path i {
	font-size: 22px;
	margin-right: 10px;
	color: var(--main-color-alt3);
}
.pricing-plans .path span {
	font-size: 20px;
	cursor: auto;
	font-weight: 600;
}
  

.pricing-plans .lamar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing-plans .lamar-container .pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;

  .plan {
    background-color: #fff;
    width: 300px;
    /* padding: 2.5rem; */
    padding: 1rem;
    margin: 12px;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    h2 {
      font-size: 16px;
      margin-bottom: 12px;
      /* color: var(--dark-color); */
      color: var(--main-color-alt3);
      font-weight: 600;
    }

    .price {
      margin-bottom: 1rem;
      font-size: 30px;
      font-weight: bold;
    }

    .students-number {
        font-size: 16px;
        margin-bottom: 18px; 
        color: var(--main-color-alt3);
    }
    .students-number span {
        font-size: 20px;
    }

    ul.features {
      list-style-type: none;
      text-align: left;
      li {
        margin: 8px;
        .fas {
          margin-right: 4px;
        }
        .fa-check-circle {
          color: #6ab04c;
        }
        .fa-times-circle {
          color: #eb4d4b;
        }
      }
    }

    button {
      border: none;
      width: 100%;
      padding: 12px 35px;
      margin-top: 1rem;
      background-color: #6ab04c;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }

    &.popular {
      border: 2px solid #6ab04c;
      position: relative;
      -webkit-transform: scale(1.08);
              transform: scale(1.08);

      span.most-popular-title {
        position: absolute;
        top: -20px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        background-color: #6ab04c;
        color: #fff;
        padding: 4px 20px;
        font-size: 18px;
        border-radius: 5px;
      }
    }

    &:hover {
      box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
    }
  }
}

.pricing-plans-core-container {
    position: relative;
    top: 80px;
    margin-bottom: 80px;
    padding: 15px 30px;
    display: grid;
    grid-template-columns: 2fr repeat(5, 1fr); /* Set column 1 width to be twice the width of other columns */
    grid-gap: 10px;
    gap: 10px; /* Adjust gap between columns if needed */

    .column {
        display: grid;
        grid-template-rows: repeat(15, 1fr); /* Ensure 15 equal rows inside each column */
        height: 100%; /* Ensure columns take full height */
        align-items: stretch; /* Make elements stretch to align */
        background-color: #f4f4f4;
        padding: 5px;
    }
    .column.one {
        margin-right: 20px;
        background-color: unset;
    }
    .column.one span {
        text-align: start;
    }
    .column.two {
        background-color: #6cb758;
        color: white;
    }
    .column:not(.one) {
        border-radius: 8px;
    }

    .column span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 1.6vh; /* Dynamic font size based on viewport height */
        font-weight: 600;
    }
    .column .merged {
        grid-row: span 4; /* Merged element takes up 4 rows */
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 5vh;
        font-weight: 500;

        p {
            font-weight: 600;
            /* margin-bottom: 0; */
        }

        .course-title {
            color: #6cb758;
            /* color: #b0aaaa; */
            /* color: #8080807a; */
            font-size: 16px;
        }
    }

    .column span.label span {
        font-size: 16px;
        font-weight: 600;
    }
    .column span.price {
        border: 1px solid white;
        border-radius: 30px;
        font-size: 2.5vh;
        font-weight: 600;
        height: 35px;
        margin-top: 5px;
        width: 80%;
        flex-direction: row;
        align-items: center;
        justify-self: center;
    }
    .column:not(.two) span.price {
        border: 1px solid #6cb758;
        color: #6cb758;
    }

    .column span.icon {
        flex-direction: row;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        margin-bottom: 5px;
    }
    .column span.type {
        font-size: 16px;
        font-weight: 600;
        flex-direction: row;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        /* height: 35px; */
    }
    .column span.type .circle-solid{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: white;
    }
    .column:not(.two) span.type .circle-solid{
        background-color: red;
    }
    .column span.check-mark {
        flex-direction: row;
        align-items: center;
    }
    .column span.button-custom {
        border-radius: 30px;
        font-weight: 600;
        color: #6cb758;
        background-color: white;
        width: 80%;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        cursor: pointer;
        height: 35px;
        transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    }
    .column span.button-custom:hover {
        background-color: #6cb758; /* Change background to green */
        color: white; /* Invert text color */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    }
    .column:not(.two) span.button-custom {
        background-color: #6cb758;
        color: white;
    }
    .column:not(.two) span.button-custom:hover {
        color: #6cb758; /* Change background to green */
        background-color: white; /* Invert text color */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    }
}


/* @media (min-width: 1200px) {
    .pricing-plans-core-container {
        height: 90.5vh;
    }
} */
