.feedback {
  position: relative;
  height: 540px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.feedback .lamar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

@media (max-width: 490px) {
  .feedback {
    height: 430px;
  }
}

@media (max-width: 490px) {
  .feedback .lamar-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .feedback .lamar-container {
    width: 100%;
  }
}
.feedback div {
  height: 100% !important;
}

.feedback .slide-container {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100%;
}
.feedback .slide-container img {
  border-radius: 4px;
  box-shadow: 0 2px 12px gray;
  transition: 0.6s ease-in-out;
}
@media (min-width: 700px){
  .feedback .slide-container img:hover {
    transform: scale(1.5);
  }
}

.feedback .mainTitle {
  color: var(--dark-color);
  margin-bottom: 30px;
  position: relative;
}
.feedback .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}
.feedback .carousel {
  width: 100%;
}
@media (max-width: 350px) {
  .feedback .carousel {
    width: 300px;
  }
  .feedback .slide-container img {
    width: 100%;
  }
}
.feedback .carousel svg {
  width: 25px;
  fill: var(--dark-color);
}