.button-component {
    position: relative;
    right: 0%;
    bottom: 127px;
}
@media (max-width: 1212px) {
    .button-component {
        bottom: 90px;
    }
}
@media (max-width: 1094px) {
    .button-component {
        bottom: 61px;
    }
}
@media (max-width: 350px) {
    .button-component {
        bottom: 58px;
    }
}
.button-component  button {
    position: relative;
    background: var(--text-color);
    color: var(--text-color);
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
    min-width: 215px;
}

.button-component  button:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background: var(--text-color);
    color: var(--text-color);
    animation: box 3s infinite;
}
@media (max-width: 1094px) {
    .button-component  button {
        letter-spacing: 1px;
        font-size: 12px;
        padding: 2px;
        min-width: unset;
        width: 150px;
        height: 38px;
    }
    
    .button-component  button:hover {
        letter-spacing: 4px;
        padding: 5px;
    }
} 
.button-component  button::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: var(--dark-color);
}
  
.button-component  button span {
    position: relative;
    z-index: 1;
}
.button-component button i {
    position: absolute;
    inset: 0;
    display: block;
}
  
.button-component button i::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 7px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--text-color);
    background: var(--dark-color);
    transition: 0.2s;
}
  
.button-component  button:hover i::before {
    width: 25px;
    left: 20%;
    animation: move 3s infinite;
}
  
.button-component  button i::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 7px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--text-color);
    background: var(--dark-color);
    transition: 0.2s;
}
  
.button-component  button:hover i::after {
    width: 25px;
    left: 80%;
    animation: move 3s infinite;
}

@media (max-width: 1212px) {

}

@media (max-width: 1094px) {
    .button-component button i::before {
        width: 15px;
        height: 5px;
    }
      
    .button-component  button:hover i::before {
        width: 15px;
    }
      
    .button-component  button i::after {
        width: 15px;
        height: 5px;
    }
      
    .button-component  button:hover i::after {
        width: 15px;
    }
}
@keyframes move {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: #27272c;
    }
    50% {
        box-shadow: 0 0 25px var(--text-color);
    }
    100% {
        box-shadow: #27272c;
    }
}
