@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.pricing-plans {
    padding: 15vh 0 100px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--text-color);
    color: var(--main-color-alt3);
    color: black;
    font-family: "Source Sans Pro", sans-serif;
}

.pricing-plans .lamar-container {
    width: unset;
}

.pricing-plans .path {
	text-align: left;
}
.pricing-plans .path i {
	font-size: 22px;
	margin-right: 10px;
	color: var(--main-color-alt3);
}
.pricing-plans .path span {
	font-size: 20px;
	cursor: auto;
	font-weight: 600;
}
  

.pricing-plans .lamar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing-plans .lamar-container .pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;

  .plan {
    background-color: #fff;
    width: 300px;
    /* padding: 2.5rem; */
    padding: 1rem;
    margin: 12px;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    h2 {
      font-size: 16px;
      margin-bottom: 12px;
      /* color: var(--dark-color); */
      color: var(--main-color-alt3);
      font-weight: 600;
    }

    .price {
      margin-bottom: 1rem;
      font-size: 30px;
      font-weight: bold;
    }

    .students-number {
        font-size: 16px;
        margin-bottom: 18px; 
        color: var(--main-color-alt3);
    }
    .students-number span {
        font-size: 20px;
    }

    ul.features {
      list-style-type: none;
      text-align: left;
      li {
        margin: 8px;
        .fas {
          margin-right: 4px;
        }
        .fa-check-circle {
          color: #6ab04c;
        }
        .fa-times-circle {
          color: #eb4d4b;
        }
      }
    }

    button {
      border: none;
      width: 100%;
      padding: 12px 35px;
      margin-top: 1rem;
      background-color: #6ab04c;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }

    &.popular {
      border: 2px solid #6ab04c;
      position: relative;
      transform: scale(1.08);

      span.most-popular-title {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #6ab04c;
        color: #fff;
        padding: 4px 20px;
        font-size: 18px;
        border-radius: 5px;
      }
    }

    &:hover {
      box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
    }
  }
}
