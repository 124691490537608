.brand {
  padding-top: var(--main-padding-top);
  position: relative;
}
@media (max-width: 1094px) {
  .brand {
    margin-bottom: 50px;
  }
}
@media (max-width: 530px) {
  .brand {
    padding-top: 40px;
  }
}
.brand .title-section {
  margin: 0 auto 0;
  width: fit-content;
}

.brand .mainTitle {
  color: var(--dark-color);
  margin-bottom: 30px;
  position: relative;
}
.brand .mainTitle::before {
  content: '';
  position: absolute;
  height: 3px;
  width: 130%;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-color);
  border-radius: 20px;
}

@media (max-width: 500px) {
  .brand .mainTitle::before {
    width: 103%;
  }
}

.clinic {
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 20px;
}

.clinic .part {
  margin: 10px;
  width: 25%;
}
.clinic .part h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--main-color-alt3);
}
.clinic .part p {
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: var(--main-color-alt3);
  line-height: 1.7;
  font-size: 16px;
}
.clinic i {
  font-size: 50px;
  color: var(--main-color-alt3);
  margin-bottom: 20px;
}

.brand .custom-shape-divider-top-1638712956 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.brand .custom-shape-divider-top-1638712956 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 90px;
  transform: rotateY(180deg);
}

.brand .custom-shape-divider-top-1638712956 .shape-fill {
  fill: rgba(0, 0, 0, 0.89);
}

.brand .lamar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 0;
  width: 93%;
  position: relative;
  left: 3.5%;
  border-radius: 10px;
}

@media (max-width: 1094px) {
  .brand .lamar-container {
    gap: 35px;
  }
}

.brand .lamar-container > div {
  position: relative;
  color: var(--main-color-alt3);
  height: 100%;
  transition: transform 0.5s, box-shadow 0.5s;
  cursor: pointer;
}


.brand .lamar-container .card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 550px !important;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  text-align: center;
  justify-content: space-between;
}
@media (max-width: 1212px) {
  .brand .lamar-container .card {
    width: 400px;
    height: 500px !important;
  }
}
@media (max-width: 1094px) {
  .brand .lamar-container .card {
    width: 275px;
    height: 275px !important;
  }
}

.brand .lamar-container .course-card {
  border: none;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}
.brand .lamar-container .course-card:hover {
  transform: translateY(-15px);
}

.brand .lamar-container .course-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--main-color-alt3);
}

.brand .lamar-container ul {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 !important;
}
.brand .lamar-container ul.right {
  text-align: right;
}

.brand .lamar-container li {
  margin-bottom: 0.5rem;
  /* color: grey; */
  color: var(--text-color);
}

.brand .lamar-container .contact-btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--text-color);
  color: var(--main-color-alt3);
  border-color: var(--text-color);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  width: 200px;
}

.brand .lamar-container .contact-btn:hover {
  background-color: var(--main-color-alt3);
  color: var(--text-color);
}
.brand .lamar-container .hr {
  width: 50%;
  position: relative;
  height: 3px;
  margin: 0;
  margin-bottom: 5px;
  left: 25%;
  opacity: 1;
  color: var(--main-color-alt3);
  transition: all 0.3s ease;
}

.brand .lamar-container .course-card:hover > .hr {
  width: 85%;
  left: 7.5%;
} 

.brand .lamar-container .button-text {
  font-weight: 600;
} 
.brand .lamar-container .button-text.arabic {
  font-size: 18px;
} 